// import { Tooltip, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const titles = {
	empty: '',

	//Dashboard
	dashboard_faturamento: 'Esse quadro resume os valores somados, mensal, anual e o total faturado.',
	dashboard_mensal: 'Esse é o faturamento MENSAL do período X até o período Y.',
	dashboard_anual: 'Esse é o faturamento ANUAL, do período X até o período Y.',
	dashboard_total: 'Esse é o total faturado acumulado, desde que você começou a usar o CashBarber.',
	dashboard_detalhes: 'Você será direcionado para a página dos relatórios.',
	dashboard_planos_detalhes: 'Clique aqui para ser direcionado ao relatório de Assinaturas → Quantidade de assinantes.',
	dashboard_planos_ativos: 'Quantidade de planos ativos no CashBarber.',
	dashboard_planos_cancelados_mensal: 'Quantidade de planos cancelados esse mês no CashBarber.',
	dashboard_planos_cancelados_total: 'Quantidade total de planos cancelados no sistema CashBarber.',
	dashboard_comandas_detalhes: 'Você será direcionado para a página das comandas.',
	dashboard_comandas_abertas: 'Esse é o total de Comandas abertas.',
	dashboard_profissionais: 'Quantidade de profissionais cadastrados no sistema.',
	dashboard_profissionais_detalhes: 'Clique aqui para ser direcionado para Minha Empresa → Cadastros → Profissionais.',
	dashboard_clientes: 'Quantidade de clientes cadastrados no seu sistema.',
	dashboard_clientes_detalhes: 'Clique aqui para ser direcionado para Gestão → Clientes → Lista de clientes.',
	dashboard_clientes_que_mais_compram: 'Aqui vai constar a informação dos 5 clientes que mais gastam na sua empresa.',
	dashboard_aniversariantes_semana: 'Clique no ícone do WhatsApp para enviar a mensagem de aniversário.',
	dashboard_aniversariantes: 'Clique no ícone do WhatsApp para enviar a mensagem de aniversário.',
	bar_cozinha: 'Clique em "Entregar" para a recepção confirmar que o produto/pedido foi entregue.',

	//Relatórios
	relatorio_faturamento_totalizadores: 'Os valores são totalizados e divididos em três categorias: total de assinaturas, total das comandas fechadas e total geral.',
	relatorio_faturamento_filial: 'Veja o faturamento de uma Unidade específica.',
	relatorio_faturamento_profissional: 'Veja o faturamento de um Profissional específico, digitando e selecionando seu nome.',
	relatorio_faturamento_cliente: 'Veja o faturamento de um cliente específico, digitando e selecionando seu nome.',
	relatorio_faturamento_inativos: '(REGISTORS INATIVOS)Essa função incluirá profissionais inativos no listagem de profissionais.',
	relatorio_faturamento_total_assinatura: 'De acordo com os filtros, este campo exibe o faturamento específico de assinaturas/plano recebido. Contabiliza apenas valores recebidos no cartão de crédito.',
	relatorio_faturamento_total_comanda: 'De acordo com os filtros, este campo exibe o faturamento específico de avulsos, produtos e extras cobrados em comandas fechadas.',
	relatorio_faturamento_total_geral: 'De acordo com os filtros, este campo exibe a soma dos faturamento de comandas fechadas e assinaturas/planos recebidos no cartão de crédito.',
	relatorio_planos_cliente: 'Selecione o cliente.',
	relatorio_planos_plano: 'Selecione o Plano.',
	relatorio_planos_status_plano: 'Filtre por planos Ativos ou Inativos.',
	relatorio_2_resultados: 'Serviços Com Clube são serviços que foram realizados por clientes que possuem alguma assinatura. Se um cliente do plano Corte faz um serviço de Barba, esse serviço vai constar em serviço Com Clube, mostrando o valor cobrado.',
	relatorio_2_resumo: 'Este relatório será utilizado para fazer o comissionamento dos serviços avulsos dos seus profissionais.',
	relatorio_4_resumo: 'Neste relatório, é possível verificar qual o status de transação dos planos: se está em dia, pendente e se é indeterminado.',
	relatorio_6_resumo: 'Neste relatório, você vai conseguir obter a informação das avaliações dadas pelos clientes ao atendimento dos seus profissionais.',
	relatorio_9_resumo: 'Neste relatório, encontram-se os clientes distintos em sua barbearia.',
	relatorio_10_resumo: 'Neste relatório, será possível visualizar o total de serviços realizados pelos seus profissionais no período filtrado, tendo como resultado o valor total desses serviços e a quantidade em que eles foram realizados.',
	relatorio_11_resumo: 'Neste relatório, você conseguirá visualizar qual forma de captação de clientes está trazendo mais clientes novos para sua barbearia.',
	relatorio_12_resumo: 'Neste relatório, será possível visualizar o histórico de entrada e saída de produtos que foram realizadas no seu sistema.',
	relatorio_13_resumo: 'Neste relatório, será possível visualizar a comissão de produto de seus profissionais no período filtrado.',
	relatorio_14_resumo: 'Neste relatório, é possível obter as informações dos clientes aniversariantes dentro do período filtrado.',
	relatorio_16_resumo: 'Neste relatório, você vai obter a informação dos seus clientes que não vêm na sua barbearia por determinado período com o telefone para contato.',
	relatorio_17_resumo: 'Neste relatório, será possível visualizar um histórico com seus novos clientes, filtrando por data de cadastro ou data de primeira visita.',
	relatorio_19_resumo: 'Neste relatório, é possível verificar a quantidade de clientes assinantes que você possui, filtrando por planos ativos, inativos ou todos.',
	relatorio_21_resumo: 'Neste relatório, é possível obter a informação dos cancelamentos de plano que sua empresa teve em determinado período.',
	relatorio_22_plano: 'É possível filtrar por vendas de planos epecíficos ou de todos.',
	relatorio_22_resumo: 'Neste relatório, é possível obter a informação de quantas vendas novas de planos a sua empresa teve dentro do período filtrado.',
	relatorio_24_resumo: 'Neste relatório, será possível obter o ticket médio de atendimento de clientes distintos (aqueles que não se repetem no período filtrado).',
	relatorio_26_resumo: 'Neste relatório, será possível visualizar o ticket médio individual por profissional.',
	relatorio_27_resumo: 'Neste relatório, será possível visualizar o ticket médio individual por cliente.',
	relatorio_28_resumo: 'Neste relatório, será possível visualizar o ticket médio individual por atendimento.',
	relatorio_29_resumo: 'Confira aqui a lilsta de espera de seus planos com quantidade LIMITADA.',
	relatorio_30_filtros: 'Confira aqui por quem foi realizado a venda do plano, quando selecionado no ato da compra.',

	//Comandas
	comandas_novo: 'Criar nova comanda.',

	//Agendamnetos
	agendamento: 'Para fazer um agendamento, clique na coluna do profissional e no horário que o agendamento será realizado, selecione o cliente, selecione o serviço, clique no + para adicionar o serviço à comanda, e clique em Salvar para registrar o agendamento.',
	agendamento_aside: 'Clique aqui para abrir as informações dos agendamentos do dia.',

	//Bar/cozinha
	bar_filial: 'Selecione a filial para visualizar os produtos a serem entregues.',
	bar_entrega: 'Clique em entregar a recepção confirmar que foi entregue o produto/pedido.',

	//Plano pré aprovado
	plano_pre_aprovado_novo: 'Clique em + Novo para cadastrar uma compra automática de plano.',
	plano_pre_aprovado_cliente: 'Selecione o cliente que deseja cadastrar com o plano pre-aprovado.',
	plano_pre_aprovado_plano: 'Selecione o plano que o cliente deseja contratar.',
	plano_pre_aprovado_data: 'Selecione a data para programar o dia em que será feita a compra automática do plano do cliente.',
	plano_pre_aprovado_cliente_cartao: 'Selecione o cartão em que será realizada a compra do cliente. Se não houver cartão cadastrado, salve e envie o link de cadastro do cartão para o cliente cadastrá-lo.',

	// Plano pré cancelado
	cliente_pre_cancelado_data: 'Selecione a data de cancelamento do plano do cliente. Programe para cancelar 1 dia antes da próxima cobrança/vencimento do plano, para evitar cobranças adicionais.',

	// Horário de feriado
	horario_feriado_filtros: 'Selecione a data, horário de funcionamento e se a barbearia estará aberta ou fechada.',

	// Clientes
	cliente_new_cpf: 'Certifique-se de que o CPF informado é válido para liberar o botão "Sincronizar com o Galax" ao lado de "Enviar".',

	// Cliente bloqueado
	cliente_bloqueado_filtros: 'Selecione até quando o cliente deverá ficar bloqueado.',

	// Comandas
	comanda_new_agendamento: 'Selecione se vai ser uma comanda de agendamento ou uma comanda de consumo.',

	// Caixa
	caixa_open_valor: 'Adicione o valor que você tem disponível para troco/malote.',

	// Histórico de caixas
	historico_caixas_filtros: 'Para localizar um caixa, utilize os filtros colocando a data em que o caixa foi aberto e a data em que ele foi fechado.',

	// Empresa parceira
	empresa_parceira_logo: 'A logo precisa ter exatamente a dimensão 1024x1024.',

	// DPote
	dpote_relatorio_filtro: 'Selecione o mês que deseja comissionar, clique em "Filtrar" e, em seguida, "Criar histórico".',

	// Comissão de produtos
	comissao_produtos_filtro_produtos: 'Selecione os produtos. Se deseja visualizar de todos, pode deixar a seleção em branco.',

	// Financeiro
	comissoes_resultados: 'Para gerar a movimentação, selecione o método de pagamento e a conta bancária.',
	balanco_resumo: 'Confira as movimentações do período filtrado.\nOs botões da esquerda para a direita são: marcar como pago, editar, visualizar e excluir.',
	contas_a_pagar_forma_pagamento: 'Selecione a forma de pagamento que foi criada em FINANCEIRO → CADASTROS → FORMAS DE PAGAMENTO',
	contas_a_pagar_conta_bancaria: 'Selecione a conta bancária que foi criada em FINANCEIRO → CADASTROS → CONTAS BANCÁRIAS.',
	contas_pagar_categoria: 'Selecione a categoria.\nPor exemplo, para "Vale", a categoria é "Comissões".',
	contas_pagar_subcategoria: 'Selecione a subcategoria.\nPor exemplo, para "Vale", a subcategoria é "Comissões".',
	conta_pagar_recorrente: 'Para ser recorrente, marque essa caixinha de Repetir Lançamento.',
	conta_receber_condicao_pagamento: 'Selecione se é À VISTA ou RECORRENTE.',
	banco_selector: 'Selecione o seu banco. Caso não encontre, pode utilizar "Outros".',
	categoria_financeira_new_filtros: 'Para criar uma nova categoria primária, deixe o campo de categoria primária em branco e clique em enviar.',
	categoria_financeira_new_tipo: 'Selecione o tipo "Contas a pagar" ou "Contas a receber".',
	forma_pagamento_new_filiais: 'Selecione a filial, a conta bancária e preencha a taxa de forma de pagamento.',

	// Dados
	dados_empresa_slug: 'Nessa opção vai ser adicionado o nome da sua barbearia sem espaços ou caracteres especiais, pois o slug de URL se tornará o seu link de agendamento. Exemplo:\ncashbarber.com.br/SlugDeUrl',
	dimensoes_logo: 'A logo deve possuir as dimensões de 1024 x 1024 pixels.\nNão sabe configurar? Clique aqui:',
	dimensoes_icone: 'A logo deve possuir as dimensões de 512 x 512 pixels.\nNão sabe configurar? Clique aqui:',

	// Configuração WhatsApp
	configuracao_whatsapp_title: 'Compatível com WhatsApp utilizado no navegador.',
	configuracao_whatsapp_info: 'Não alterar as informações que possuem #, pois essas informações são preenchidas automaticamente pelo sistema.',

	// Filial
	filial_button_new: 'Clique em "+ Novo" para adicionar uma nova Filial ao seu sistema.',

	// Profissional
	profissional_new_button: 'Clique em "+ Novo" para criar o cadastro de um novo profissional',
	profissional_new_servicos_info: 'Adicione os serviços que o seu profissional realizará e selecione a comissão que ele receberá dos serviços avulsos (se ele tiver uma comissão diferente da cadastrada na categoria dos serviços).',
	profissional_new_horarios_info: 'Selecione o horário que seu profissional estará disponível para atendimento.',
	profissional_new_intervalos_info: 'Defina os horários de intervalo do seu profissional.',
	profissional_new_dpote_info: 'Comissões EXTRAS da assinatura/Dpote.',
	profissional_new_dpote_adicional: 'É uma comissão utilizada como bonificação, é calculada em cima da produção do barbeiro.',
	profissional_new_dpote_diferenciada: 'É a comissão que será somada à sua comissão base do dPote, que fica em minha empresa → dados.',
	profissional_new_email: 'O E-mail é único e não pode repetir.',
	profissional_new_cpf: 'O CPF é único e não pode repetir.',
	profissional_new_tipo: 'O TIPO só pode ser escolhido no ato de criação do usuário e não pode ser alterado.',
	profissional_new_filial: 'A filial só pode ser selecionada na criação do usuário, depois não pode ser alterada.',

	// Serviços
	servico_new_fichas: 'Fichas são adicionadas nos serviços que vão estar zerados no seu plano de assinatura. O valor de fichas é sempre proporcional ao tempo de duração do serviço. Exemplo:\nse o serviço dura 30 minutos você adicionará 30 fichas.',
	servicos_button_new: 'Clique em "+ Novo" para cadastrar um novo serviço.',

	// Produtos
	produtos_new_recompra: 'Em quanto tempo o cliente deverá efetuar uma nova compra desse produto? Quanto tempo +- esse produto leva para acabar quando utilizado diariamente.',
	produtos_button_new: 'Clique em +Novo para adicionar um novo produto ao seu sistema.',

	// Categorias
	categorias_new_resumo: 'Descontos e comissões adicionados são para serviços/produtos avulsos.',
	categorias_new_desconto: 'Adicione a porcentagem de desconto que você deseja dar nessa categoria (para serviços avulsos); se não deseja dar desconto, preencha o campo com o valor 0 (zero).',
	categorias_new_comissao: 'Aqui é adicionada a comissão geral dos serviços avulsos/extras dos seus profissionais, adicionando a comissão automaticamente a todos os serviços cadastrados nessa categoria.',
	categorias_new_tipo: 'Selecione se essa categoria vai ser de Serviço ou de Produto.',

	// Planos
	plano_new_galax_id: 'Galax ID é o Código do plano no cel_cash. No cel_cash, vá em Planos (painel esquerdo), e em sua lista de planos, você orá visualizar Código - Nome - Valor, etc. O Galax ID é o Código localizado no lado esquerdo do plano. Digite este código aqui.',
	plano_new_quantidade_maxima: 'Se não preenchido, o plano tem venda ilimitada.',
	plano_new_contrato: 'Clique em Adicionar Contrato para adicionar os Termos de Uso do plano de assinatura no sistema CashBarber. O arquivo precisa ser em PDF para adicionar ao sistema.',
	plano_new_categoria_servico: 'Adicione as Categorias de Serviços que você deseja dar desconto nesse plano de assinatura. Por exemplo, para ter desconto em serviços extras adicione aqui a categoria e a % de desconto.',
	plano_new_servicos: 'Para um plano ILIMITADO, adicione o desconto 100% e quantidade de gratuitos 100.\nPara um plano LIMITADO adicione o desconto 0% (ou a % desejada) e quantidade de gratuitos 100 (ou a quantidade desejada).',
	plano_new_dias: 'Selecione os dias da semana em que há Gratuidade (que os serviços devem ser gratuitos/zerados) nesse plano.',
	plano_new_categoria_produtos: 'Selecione as categorias de Produto e a % de desconto que os assinantes devem receber.',
	plano_new_produtos: 'Selecione produtos específicos para definir o valor final.',
	plano_new_gratuitos: 'Coloque aqui a quantidade de serviços gratuitos que seus clientes terão para utilizar durante o período de 1 mês.',

	// Genéricos
	csv: 'Faça o download das informações abaixo em formato CSV.',
}

export default function BetterTooltip(props) {
	if (!props.title) {
		props.title = "empty";
	}
	return (
		<OverlayTrigger placement={props.placement ?? 'top'} trigger={['focus']}
			overlay={
				<Tooltip className="mt-2 mb-2" >
					{titles[props.title].includes('\n') ?
						titles[props.title].split('\n').map(line => (
							<p style={{ margin: 0 }} >{line}</p>
						))
						:
						<p style={{ margin: 0 }} >{titles[props.title]}</p>
					}
					{props.link ?
						<a href={props.link} target="_blank" >{props.link}</a>
						:
						null
					}
				</Tooltip>
			} >
			<i tabIndex="0" style={{ cursor: "pointer", ...props.iconStyle }} className={`fas fa-info-circle ${props.iconClass ?? ''}`}></i>


		</OverlayTrigger>
	)
}