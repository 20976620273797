import axios from "axios";
import { Constants } from '../utils/Constants';

export function get(id) {
	return axios.get(`${Constants.baseUrl}/perfil`);
}

export function update(id, form) {
	return axios.post(`${Constants.baseUrl}/perfil`, form);
}

export const registerPush = (form) => {
	return axios.post(`${Constants.baseUrl}/perfil/push`, form);
};

export const removePush = (data) => {
	return axios.post(`${Constants.baseUrl}/perfil/push/delete`, data);
};

// export const checkIfPushIsRegistered = (data) => {
// 	return axios.post(`${Constants.baseUrl}/perfil/push/check`, data);
// };