import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import * as utils from "../../../_metronic/utils/utils";
import { Constants } from "../../utils/Constants";

function Login(props) {
	const [loading, setLoading] = useState(false);
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({ paddingRight: "2.5rem" });

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	return (
		<>
			<div className="kt-login__head">
			</div>

			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>Entrar</h3>
					</div>

					<Formik initialValues={{ email: "", password: "" }}
						validate={values => {
							const errors = {};

							if (!values.email) {
								errors.email = "O e-mail é uma informação obrigatória.";
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = "Você precisa informar um e-mail válido.";
							}

							if (!values.password) {
								errors.password = "A senha é uma informação obrigatória.";
							}

							return errors;
						}} onSubmit={(values, { setStatus, setSubmitting }) => {
							enableLoading();

							login(values.email, values.password).then(res => {
								disableLoading();

								utils.setStorage('authToken', res.data.token, null);

								localStorage.setItem('timeZone', res.data.time_zone);
								document.cookie = `authToken=${res.data.token}`;
								document.cookie = `user=${JSON.stringify(res.data.user)}`;
								window.localStorage.setItem('acesso_pump', JSON.stringify(res.data.user.empresa.emp_acesso_pump));
								props.login(res.data);
							}).catch((err) => {
								disableLoading();

								setSubmitting(false);

								if (err.response?.data?.errors) {
									setStatus(err.response.data.errors[0]);
								} else {
									setStatus("Usuário ou senha incorretos.");
								}
							});
						}}>

						{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
								{status ? (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								) : null}

								<div className="form-group">
									<TextField type="email" label="Seu e-mail" margin="normal" className="kt-width-full" name="email"
										onBlur={handleBlur} onChange={handleChange} value={values.email} helperText={touched.email && errors.email}
										error={Boolean(touched.email && errors.email)} />
								</div>

								<div className="form-group">
									<TextField type="password" margin="normal" label="Sua senha" className="kt-width-full" name="password"
										onBlur={handleBlur} onChange={handleChange} value={values.password} helperText={touched.password && errors.password}
										error={Boolean(touched.password && errors.password)} />
								</div>

								<div>
									<a className="forgot-password" href="/password/forgot">Esqueceu sua senha?</a>
								</div>

								<div style={{ alignSelf: 'baseline', marginTop: 8 }}>
									<a style={{ display: 'block' }} target='_blank' href={Constants.termosUsoURL}>Termos de uso</a>
									<a target='_blank' href={Constants.politicasPrivacidadeURL}>Políticas de privacidade</a>
								</div>

								<div className="kt-login__actions">
									<button id="kt_login_signin_submit" type="submit" disabled={isSubmitting} className={`btn btn-primary btn-elevate kt-login__btn-primary 
                    						${clsx({ "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading })}`}
										style={loadingButtonStyle}>Entrar!</button>
								</div>
							</form>
						)}
					</Formik>

				</div>
			</div>
		</>
	);
}

export default connect(null, auth.actions)(Login)

