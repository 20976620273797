import { Constants } from "./Constants";
import { registerPush, removePush } from "../crud/perfil.crud";

export async function registerServiceWorker() {
	if (!('serviceWorker' in navigator)) {
		throw Error('Service workers are not supported by this browser 1');
	}

	await navigator.serviceWorker.register('/serviceWorker.js');
};

export async function getReadyServiceWorker() {
	if (!('serviceWorker' in navigator)) {
		throw Error('Service workers are not supported by this browser 2');
	}

	return navigator.serviceWorker.ready;
};

export async function getCurrentPushSubscription() {
	const sw = await getReadyServiceWorker();

	return sw.pushManager.getSubscription();
};

export async function registerPushNotifications() {
	if (!('PushManager' in window)) {
		throw Error('Push notifications are not supported by this browser.');
	}

	const existingSubscription = await getCurrentPushSubscription();

	if (existingSubscription) {
		throw Error('Existing push subscription found.');
	}

	const sw = await getReadyServiceWorker();
	const subscription = await sw.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: Constants.webPushPublicKey
	});

	await sendPushSubscriptionToServer(subscription);
};

export async function unregisterPushNotifications() {
	const existingSubscription = await getCurrentPushSubscription();

	if (!existingSubscription) {
		throw Error('No existing push subscription found.');
	}

	await deletePushSubscriptionFromServer(existingSubscription);

	await existingSubscription.unsubscribe();
};

export async function sendPushSubscriptionToServer(subscription) {
	registerPush({
		cli_push_token: JSON.stringify(subscription)
	}).catch(err => {
		console.log(err);
	}).then(res => {
		if (res?.status == 200) {
		}
	});
};

export async function deletePushSubscriptionFromServer(subscription) {
	removePush({ endpoint: subscription.endpoint }).catch(err => {
		console.log(err);
	}).then(res => {
		if (res?.status == 200) {
		}
	});
};
