import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const titles = {
	//Dashboard
	dashboard_faturamento: 'Esse quadro resume os valores somados, mensal, anual e o total faturado.',
	dashboard_mensal: 'Esse é o faturamento MENSAL do período X até o período Y.',
	dashboard_anual: 'Esse é o faturamento ANUAL, do período X até o período Y.',
	dashboard_total: 'Esse é o TOTAL faturado, do período X até o período Y.',
	dashboard_detalhes: 'Você será direcionado para a página dos relatórios.',
	dashboard_planos_detalhes: 'Clique aqui para ser direcionado ao relatório de Assinaturas → Quantidade de assinantes.',
	dashboard_planos_ativos: 'Quantidade de planos ativos no CashBarber.',
	dashboard_planos_cancelados_mensal: 'Quantidade de planos cancelados esse mês no CashBarber.',
	dashboard_planos_cancelados_total: 'Quantidade total de planos cancelados no sistema CashBarber.',
	dashboard_comandas_detalhes: 'Você será direcionado para a página das comandas.',
	dashboard_comandas_abertas: 'Esse é o total de Comandas abertas.',
	dashboard_profissionais: 'Quantidade de profissionais cadastrados no sistema.',
	dashboard_profissionais_detalhes: 'Clique aqui para ser direcionado para Minha Empresa → Cadastros → profissionais.',
	dashboard_clientes: 'Quantidade de clientes cadastrados no seu sistema.',
	dashboard_clientes_detalhes: 'Clique aqui para ser direcionado para Gestão → Clientes.',
	dashboard_clientes_que_mais_compram: 'Aqui vai constar a informação dos 5 clientes que mais gastam na sua empresa.',
	dashboard_aniversariantes_semana: 'Clique no ícone do WhatsApp para enviar a mensagem de aniversário.',

	//Relatórios
	relatorio_faturamento_filial: 'Veja o faturamento de uma Unidade específica.',
	relatorio_faturamento_profissional: 'Veja o faturamento de um Profissional específico, digitando e selecionando seu nome.',
	relatorio_faturamento_cliente: 'Veja o faturamento de um cliente específico, digitando e selecionando seu nome.',
	relatorio_faturamento_inativos: 'Ao selecionar essa função, irá mostrar os registros inativos. (Exemplo: um profissional ou filial inativa.)',
	csv: 'Faça o download das informações abaixo em formato CSV.',
	relatorio_faturamento_total_assinatura: 'De acordo com os filtros, este campo exibe o faturamento específico de assinaturas/plano recebido. Contabiliza apenas valores recebidos no cartão de crédito.',
	relatorio_faturamento_total_comanda: 'De acordo com os filtros, este campo exibe o faturamento específico de avulsos, produtos e extras cobrados em comandas fechadas.',
	relatorio_faturamento_total_geral: 'De acordo com os filtros, este campo exibe a soma dos faturamento de comandas fechadas e assinaturas/planos recebidos no cartão de crédito.',

	//Relatório de planos
	relatorio_planos_cliente: 'Selecione o cliente.',
	relatorio_planos_plano: 'Selecione o Plano.',
	relatorio_planos_status_plano: 'Filtre por planos Ativos ou Inativos.',

	//Comandas
	comandas_novo: 'Criar nova comanda.',

	//Agendamnetos
	agendamento: 'Para fazer um agendamento, clique na coluna do profissional e no horário que o agendamento será realizado, selecione o cliente, selecione o serviço, clique no + para adicionar o serviço à comanda, e clique em Salvar para registrar o agendamento.',
	agendamento_aside: 'Clique aqui para abrir as informações dos agendamentos do dia.',

	//Bar/cozinha
	bar_filial: 'Selecione a filial para visualizar os produtos a serem entregues.',
	bar_entrega: 'Clique em entregar a recepção confirmar que foi entregue o produto/pedido.',

	//Plano pré aprovado
	plano_pre_aprovado_novo: 'Clique em + Novo para cadastrar uma compra automática de plano.',
	plano_pre_aprovado_cliente: 'Selecione o cliente que deseja cadastrar com o plano pre-aprovado.',
	plano_pre_aprovado_plano: 'Selecione o plano que o cliente deseja contratar.',
	plano_pre_aprovado_data: 'Selecione a data para programar o dia em que será feita a compra automática do plano do cliente.',
	plano_pre_aprvado_cartao: 'Selecione o cartão em que será realizada a compra do cliente. Se não houver cartão cadastrado, salve e envie o link de cadastro do cartão para o cliente cadastrá-lo.',

	// Clube do assinante
	empresas_parceiras_novo_button: 'Clique em novo para cadastrar sua empresa parceira para oferecer os cupons de desconto aos seus assinantes.',

	// DPote
	dpote_relatorio_criar_historico: 'Criar histórico para comissionar o período filtrado - ao clicar aqui, o sistema irá te levar ao próximo passo.',

	// Financeiro
	forma_pagamento_new_checkbox: 'Marcar essa caixinha faz com que este método de pagamento entre como pago/recebido automaticamente no seu sistema.',
	banco_selector: 'Selecione o seu banco. Caso não encontre, pode utilizar "Outros".',

	// Filial
	filial_button_new: 'Clique em "+ Novo" para adicionar uma nova Filial ao seu sistema.',
	filial_oculta: 'Marque esta caixinha para deixar sua filial oculta. Filiais ocultas não aparecem para os clientes.',
	filial_cellcash: 'Marque esta caixinha para inficar que essa é a filial que recebe as assinaturas.',

	// Profissional
	cadastro_agendamento: 'Quando marcada, permite que o profissional faça o cadastro do agendamento de um cliente. Ele pode agendar um cliente já cadastrado no sistema, mas não pode excluir o agendamento.',
	edicao_agendamento: 'Quando marcada, permite que o profissional aumente/diminua o tempo de duração de agendamentos e movimente eles para fazer pequenos ajustes/encaixes.',
	gerenciar_produtos_comanda: 'Quando marcada, permite que o profissional faça a adição de produtos vendidos diretamente na comanda do cliente.',
	gerenciar_servicos_comanda: 'Quando marcada, permite que o profissional adicione mais serviços que ele realizou no cliente durante o atendimento.',
	cadastrar_bloqueio: 'Quando marcada, permite que o profissional faça bloqueios na agenda para evitar que algum cliente faça um agendamento enquanto ele estiver ausente. Ele pode fazer um bloqueio, mas não consegue remover o mesmo.',
	remocao_folga: 'Quando marcada, permite que o profissional remova alguma folga que ele tenha cadastrada na agenda dele caso nesse dia ele decida atender normalmente.',
	profissional_new_add: 'Adicionar',

	// Serviços
	servicos_button_new: 'Clique em "+ Novo" para cadastrar um novo serviço.\nhttps://anovabarbearia.cademi.com.br/area/produto/item/3516052',
	servicos_new_a_partir: 'Quando marcado, vai aparecer no link de agendamento para o cliente que esse serviço é a partir de X Reais, ou seja, que o serviço pode ter alteração de valor. Utilizado em serviços que tem níveis de preço, como platinado, selagem, luzes, pigmentação, entre outros. ',
	servicos_new_oculto: 'Quando marcado, oculta seu serviço do link de agendamento dos clientes e ele ficará visível somente para a barbearia. ',
	servicos_new_encaixe: 'Quando marcado, zera automaticamente o tempo de duração desse serviço, tornando ele um encaixe quando ele for agendado com um serviço que não está marcado como serviço de encaixe. Exemplo: se você marcar uma sobrancelha como encaixe e ela for agendada com um Corte, o tempo de duração da sobrancelha estará zerado no agendamento.',
	servicos_new_avulso: 'Quando marcado, considera o serviço como avulso no PUMP, e não como um serviço extra.',

	// Produtos
	produtos_button_new: 'Clique em +Novo para adicionar um novo produto ao seu sistema.\nhttps://anovabarbearia.cademi.com.br/area/produto/item/3516122',

	// Planos
	plano_new_renovacao: 'Marcando essa caixa você torna o plano recorrente, selecione ela para ativar a recorrência desse plano de assinatura.',
	plano_new_oculto: 'Marcando essa caixa você torna o plano Oculto no link de agendamento dos clientes, tornando o plano visível apenas para a barbearia.',


	// Genéricos
	add_button: 'Clique aqui para adicionar.',
	save_button: 'Clique em enviar para salvar.',
	add_categoria_button: 'Clique no + para criar uma categoria.',

	// Menu
	etapa_1: 'Etapa 1',
	etapa_2: 'Etapa 2',
	etapa_3: 'Etapa 3',
}

export default function BetterInherentTooltip(props) {
	return (
		<OverlayTrigger placement={props.placement ?? 'top'} delay={100}
			overlay={
				<Tooltip className="mt-2 mb-2" >
					<p style={{ margin: 0 }} >{titles[props.title]}</p>
				</Tooltip>
			} >
			{props.children}

		</OverlayTrigger>
	)
}